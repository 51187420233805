/* Example CSS for sliders */
/* For Chrome, Safari, Edge, Opera */
.inputTypeNumber::-webkit-inner-spin-button,
.inputTypeNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.inputTypeNumber {
  -moz-appearance: textfield;
  outline: none;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  @apply bg-primary-grey-light opacity-70 transition-opacity duration-200;
  margin-bottom: 5rem;
  cursor: pointer;
}

.slider:hover {
  opacity: 1;
}

/* The slider handle (thumb) - for WebKit browsers */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  @apply bg-primary-grey-dark cursor-pointer;
}

/* The slider handle (thumb) - for browsers that support it */
.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  @apply bg-primary-accent cursor-pointer;
}
