.imageStyle {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
  width: auto;
  height: 100%;
  margin-bottom: 15px;
}

.textStyle {
  font-size: 0.75rem;
  text-align: center;
  white-space: pre-line;
}
