@import "./assets/css/toastify.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @keyframes dropdown {
    0% {
      opacity: 0;
      transform: translateY(-10px) scale(0.95);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  .animate-dropdown {
    animation: dropdown 0.2s ease-out forwards;
  }
}

@media screen {
  .print-only {
    display: none;
  }
}

@media print {
  .print-only {
    display: block;
  }
}

@media print {
  .page-break {
    page-break-before: always;
  }
}

body {
  background-image: url("/public/images/wall.png");
}
